import React from 'react';
import { openPopupWidget } from "react-calendly";
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCashRegister, faEnvelope, faMoneyBill1Wave } from '@fortawesome/free-solid-svg-icons';
import { FaCashRegister } from 'react-icons/fa';
import { BsCashCoin  } from 'react-icons/bs';

export default function Booking() {

    const url = "https://calendly.com/minhamagic"
    
    const onClickFunc = () => openPopupWidget({url})
    
  return <div>
            <Container className="section book" > 
                <Row className="justify-content-md-center p-5" >
                  <Col lg={8} >
                    
                    <h1 className="h1-headings">BOOKING</h1>
                    <div className="text-center p-5">
                      <button onClick={onClickFunc} className="book-btn btn-dark pl-5 ml-5 pr-5 mr-5"><div>Schedule a meeting</div><div>with Minha</div></button>
                    </div>
                    <p>
                    If you would prefer a remote session, a house visit, or a free phone consultation please click the calendly link above to schedule an appointment with me. Scheduling will be complete once payment is received.</p>
                    
                    <div className="text-center p-5">
                    <button 
                      className="book-btn btn-dark pl-5 ml-5 pr-5 mr-5">
                      <a target="_blank" className="btn-link" rel="noopener noreferrer" href="https://ommmangowellness.acuityscheduling.com/schedule.php"><div>Schedule a meeting with Minha at Ommango Wellness</div></a>
                      </button>
                    </div>
                    <p>Please click the link above to book an in person session with me at Ommmango Wellness! Ommmango Wellness is a black woman owned wellness center in Brooklyn. Aside from my different types of healing sessions, they offer acupuncture, massage, and reiki!</p>

                    <div className="text-center p-5">
                      <button onClick={onClickFunc} className="book-btn btn-dark"><div>Free</div><div>Consultation</div></button>
                    </div>
                    <h3>PAYMENT</h3>
                    <p>
                    I accept payment via venmo and cashapp as well as apple pay upon request. Venmo @MInha-DC,Cashapp  $Minha222 </p>


                    <div className='d-flex justify-content-around pb-5'>
                        <a href="https://venmo.com/code?user_id=1752421776752640496&created=1636305464.39676&printed=1" target="_blank" rel="noopener noreferrer"
                        > <BsCashCoin size={70} /><div>Venmo</div></a>

                        <a href="https://cash.app/$Minha222" target="_blank" rel="noopener noreferrer" > <FaCashRegister size={70} /><div>Cashapp</div></a>
                    </div>

                    <p>
                      If you have questions or aren’t sure which type of session to book but don’t want a phone call, feel free to email me:  
                      <a href = "mailto: minhamagic@gmail.com">minhamagic@gmail.com</a> or dm me on Instagram 
                      <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/minhamagic/">@minhamagic</a>
                      . I will reply faster on instagram!
                    </p>

                    <p>If you would like to book multiple ongoing sessions but aren’t sure you can afford it, reduced rates are available for multiple ongoing sessions together. dm me on Instagram or email me to discuss.</p>

                    <p>My goal is to remain as accessible as possible! It is my life work to help you on your healing journey, so if we can make it work with your budget while still honoring our needs and desires that is ideal and I welcome the conversation.</p>
                  </Col>
                </Row>
            </Container>
        </div>;
}
