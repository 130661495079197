import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import About from './components/About.jsx';
import Testimonial from './components/Testimonial';
import Technique from './components/Technique';
// import NavScroll from './components/NavScroll';
import Navigation from './components/Nav';
import Booking from './components/Booking';
import Video from './components/Video';

import { openPopupWidget } from "react-calendly";

function App() {

  const url = "https://calendly.com/minhamagic/"
  const onClickFunc = () => openPopupWidget({url})

  return (
    <div className="App">
      <Navigation />
      <Video />
      <Testimonial />
      <Technique />
      <Booking />
      <About />
      
      <button 
      onClick={onClickFunc} className="sticky-book-btn book-btn btn-dark pl-5 pr-5"
      ><div>BOOK</div>
      <div>NOW</div>
      </button>
    </div>
  );
}

export default App;