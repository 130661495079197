import React from 'react';

const Video = () => {

 return (
  <>
  <div className="video-container">
    <video 
      // autoPlay 
      // loop
      // playsInLine
      // className="video-background" 
      // muted
      // autoBuffer
      autoPlay="autoplay"
      loop="loop"
      muted
      playsInline
    >
    <source src="https://i.imgur.com/ASn849R.mp4" type="video/mp4"/>
  </video>
  </div>
  </>
 )
}

export default Video