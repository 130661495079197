import React from "react";

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'


const data = [
  {
   image: "https://i.imgur.com/XSzBHEZ.jpeg", 
   title:"Intuitive Tarot/Oracle Reading",
   description:"Tarot is an ancient tool for divination and can be great for when you are seeking guidance from spirit. Through this practice we call upon our guides and honorable ancestors to commune harmoniously through the reader, who acts as a channel to deliver messages, wisdom, and guidance with the help of the cards. We set the intentions for these messages to be received in alignment with your highest good and in accordance with divine timing."
  },
  {
    image: "https://i.imgur.com/ldMCpHE.jpeg", 
    title:"Mystic Healing",
    description:"This healing technique has a vague name because it is a practice that I have cultivated by channeling and listening to all of my lineages (ancestral, spiritual, ect.). It is  similar to the process of soul retrieval and it is supported by reiki but it is not a traditional reiki  session as it goes deeper into the astral realm. I often receive visions, guidance, and downloads that I share with you after the session. It is good to keep in mind that healing is working and medicine is being channeled regardless of the visions, downloads, and messages I relay verbally afterwards. That storytelling process is valuable and great for deeper understanding and integration, but it is not more important than the session itself."
   },
   {
    image: "https://i.imgur.com/q2SBdat.jpeg", 
    title:"SATYA (Sensory Awareness Training) movement therapy",
    description:"SATYA is s  a mindful gentle movement practice done on the floor to balance the structure, open the body without force and to reduce fatigue. This modality combines different therapeutic movement practices including Feldenkrais, Kline, Cranial Sacral, and Yoga. It is accessible to bodies of varying abilities and injuries. It is a way to undo holding patterns in the body that stem from  traumatic events and/or genetic predisposition. Together we allow your brain to settle into the  autonomic nervous system (reptilian brain) so that you can access your inner physician holding space for the body's own wisdom. We try to stay out of the logical thinking mind as this tends to  get in the way of the potent healing that occurs from the container of this practice. If there was one short way to describe SATYA it would be: coming home to the space of  divine presence- finding the stillness in movement and the movement in stillness."
   } 
]
function Technique(){

  const renderService = () =>{
    return(
      data.map((service) => (
        <>
        <h3>{service.title}</h3>
          <Image fluid className="image" src={service.image} />
          <p className="align-text-bottom">{service.description}</p>
        </>
      ))
    )
  }

  return (
    <Container className="section technique">
    
      <Row  className="justify-content-md-center p-5">
        
        <Col lg={8}>
        <h1 className="h1-headings">HEALING TECHNIQUES</h1>
         {renderService()}
        </Col>
      </Row>
    </Container>
  );
}

export default Technique;
