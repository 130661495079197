import React from "react";
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import { FaInstagram,  FaEnvelope  } from 'react-icons/fa';

function About(){
  return (
    <Container className="section about" fluid="md"> 
    <Row className="justify-content-md-center p-5" >
      <Col lg={8}>
        <h1 className="h1-headings">About Minha</h1>
        <Image src = "https://i.imgur.com/rCspBgF.jpg" alt ="about" fluid className="image"/>
      
        <div> 
          <p className="align-text-bottom">With both heavy Pisces placements and heavy Aries placements in my natal chart, I am the beginning and the end. I am the gateway from the old into the new, embodying cycles of death and rebirth. I connect with the mysterious ocean of collective consciousness that unites all sentient beings and the individual vessel that acts as a match igniting a cardinal force to be reckoned with. I believe and understand birth through destruction. I believe in and understand sacred rage. I am not the type of healer that will spiritual bypass all the muck to preach love and light. I am a big believer in the importance of healing trauma, breaking cycles of abuse, and integrating past selves.</p>

          <p>
          I am able to hold seemingly disparate truths. I am able to hold space for paradox and contradiction. I am an anarchist who has worked with a black and indigenous lead abolitionist grassroots organization. I believe in the power of mutual aid (not charity), of resisting state sanctioned violence by any means necessary, and I believe in implementing transformative justice structures outside of the carceral system. I do not see my political work as separate from my healing work. It is simply another modality for healing.
          </p>

          <p>
          I love to cultivate and repair my relationship with the earth by gardening and creating medicine in the form of home cooked meals, tinctures, oils, and tonics. I infuse the medicine with energetic healing properties specific to the ingredients used, the moon cycle during the time of its creation, and the needs of the individuals receiving it. The more we live in and nurture our symbiotic relationship to the earth the more we continue to heal each other.
          </p>

          <div className="d-flex justify-content-around"> 
              <a href = "mailto: minhamagic@gmail.com"> 
              <FaEnvelope size={70}/></a>

              <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/minhamagic/">
              <FaInstagram size={70} /></a>
          </div>
        </div>
     </Col>
    </Row>
    </Container>

  );
}

export default About;