import React from 'react';

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { Container } from 'react-bootstrap';
import { scroller } from 'react-scroll';

export default class Navigation extends React.Component{

  constructor(props){
    super(props);

    this.listener = null;
    this.state = {
      status: "top"
    }
  }

  componentDidMount(){
    this.listener = document.addEventListener("scroll", e=> {
      let scrolled = document.scrollingElement.scrollTop;

      if(scrolled >= 800){
        if(this.state.status !== "bottom"){
          this.setState({ status: "bottom" });
        }
      } else {
        if( this.state.status !== "top"){
          this.setState({ status: "top"})
        }
      }
    });
  }

  componentDidUpdate(){
    document.removeEventListener("scroll", this.listener)
  }


  render(){
  return(
  <>
   <Navbar 
   scrolling 
   expand="md" 
   collapseOnSelect
   fixed="top"
   className={ this.state.status === "top"? "bg-transparent text-white" : "bg-white text-black"  }
   >
      <Container>
      <Navbar.Brand
        onClick={() => scroller.scrollTo('about', {
          smooth: true,
          offset: 0,
          duration: 500
        })}

        href="#home" 
        className={ this.state.status === "top"? "text-white" : "text-black"}
        >
          <div className="logo">M i n h a</div> 
          <div className="logo">M a g i c</div>
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link
          onClick={() => scroller.scrollTo('about', {
            smooth: true,
            offset: 0,
            duration: 500
          })} 
          className={ this.state.status === "top"? "text-white" : "text-black"}
          >A B O U T</Nav.Link>
          
          <Nav.Link 
          onClick={() => scroller.scrollTo('technique', {
            smooth: true,
            offset: 0,
            duration: 500
          })} 
          className={ this.state.status === "top"? "text-white" : "text-black"}
          >H E A L I N G &nbsp;&nbsp;T E C H N I Q U E S</Nav.Link>

          <Nav.Link 
          onClick={() => scroller.scrollTo('testimonial', {
            smooth: true,
            offset: -280,
            duration: 500
          })}
          className={ this.state.status === "top"? "text-white" : "text-black"}
          >T E S T I M O N I A L S</Nav.Link>
          
          <Nav.Link
          onClick={() => scroller.scrollTo('book', {
            smooth: true,
            offset: -350,
            duration: 500
          })}
          className={ this.state.status === "top"? "text-white" : "text-black"}
          >B O O K &nbsp;&nbsp; N O W</Nav.Link>

        </Nav>
      </Navbar.Collapse>
      </Container>
      </Navbar>
  </>
  )
  }
}
