import React from "react";
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Carousel from 'react-bootstrap/Carousel'
import Container from 'react-bootstrap/Container'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons'


const testimonials = [
  {"text": "I had a Jewish Mystic Healing session with Minha a few days ago and let me tell you, they are POWERFUL. Minha has a way of telling stories and putting the pieces of ones life together in a way that I’ve never experienced before. Our session helped me shed so many layers of things that were no longer serving me. If you are interested in a session, I highly recommend reaching out to them. Thank You so much Minha!",  "name": "Yaya"},
  {"text": "Receiving healing from Minha is like no other experience I have ever had. They are truly a gift in this world. As soon as they placed their hands on me, I felt their warmth, and the letting go of pain and suffering. After our session, they provided even more – space and guidance from which I could weave it all together leaving feeling light, happy, warm and whole. I can’t wait to see them again!",  "name": "Shekhina"},
  {"text": "The gift of working with Minha keeps giving. I have received healing and guidance from them. I've collaborated with them in community healing services, Full moon dancing and psychic reading downloads! I've witnessed their dedication to activism and seva. As a trans Indo-Caribbean, West Indian survivor, I feel safe and taken care of when working with or receiving from Minha and their angelic guides.", "name": "Nandani Devi"}
]

function Testimonial(){
  return (
    <Container className="section testimonial">
      <Row className="d-flex align-items-center justify-content-md-center" >
      <Col className="tesimonial-wapper" lg={12}>
        <h1 className="h1-headings">Testimonials</h1>
        <Carousel variant="dark" nextIcon prevIcon indicators={false} >
          <Carousel.Item interval={1000} className="fixed-height">
            <Col sm={12} md={10} lg={8} className="mx-auto h-100 p-3">
              <blockquote  className="blockquote blockquote-custom bg-white p-5 shadow">
                    <div className="blockquote-custom-icon quote-left"><FontAwesomeIcon icon={faQuoteLeft} color="black" size="xl" /></div>
                    <div className="blockquote-custom-icon quote-right"><FontAwesomeIcon icon={faQuoteRight} color="black" size="xl" /></div>
                    <p className="mb-0 mt-2 font-italic">{testimonials[0].text}</p>
                    <footer className="blockquote-footer blockquote-footer-custom pt-4 mt-4 border-top">{testimonials[0].name}</footer>
              </blockquote>
            </Col>
          </Carousel.Item>
          <Carousel.Item interval={1000} className="fixed-height">
            <Col sm={12} md={10} lg={8} className="mx-auto h-100 p-3">
              <blockquote  className="blockquote blockquote-custom bg-white p-5 shadow">
                    <div className="blockquote-custom-icon quote-left"><FontAwesomeIcon icon={faQuoteLeft} color="black" size="xl" /></div>
                    <div className="blockquote-custom-icon quote-right"><FontAwesomeIcon icon={faQuoteRight} color="black" size="xl" /></div>
                    <p className="mb-0 mt-2 font-italic">{testimonials[1].text}</p>
                    <footer className="blockquote-footer blockquote-footer-custom pt-4 mt-4 border-top">{testimonials[1].name}</footer>
              </blockquote>
            </Col>
          </Carousel.Item>
          <Carousel.Item interval={1000} className="fixed-height">
            <Col sm={12} md={10} lg={8} className="mx-auto h-100 p-3">
              <blockquote  className="blockquote blockquote-custom bg-white p-5 shadow">
                    <div className="blockquote-custom-icon quote-left"><FontAwesomeIcon icon={faQuoteLeft} color="black" size="xl" /></div>
                    <div className="blockquote-custom-icon quote-right"><FontAwesomeIcon icon={faQuoteRight} color="black" size="xl" /></div>
                    <p className="mb-0 mt-2 font-italic">{testimonials[2].text}</p>
                    <footer className="blockquote-footer blockquote-footer-custom pt-4 mt-4 border-top">{testimonials[2].name}</footer>
              </blockquote>
            </Col>
          </Carousel.Item>
        </Carousel>
      </Col>
      </Row>
    </Container>
  );
}

export default Testimonial;